import axios from "axios";

export async function getUserName(userId: string): Promise<string> {
    const cachedData = getUserCacheFromLocalStorage();
    if (cachedData[userId]) {
        return cachedData[userId];
    }
    const hostname = window.location.hostname;
    const response = await axios.get(`https://${hostname}/attask/api-internal/USER/${userId}?fields=name`)
    const userName = response.data?.data?.name;
    saveUserCacheToLocalStorage({...cachedData, [userId]: userName});
    return userName;
}

function getUserCacheFromLocalStorage() {
    const cachedData = localStorage.getItem('userCache');
    return cachedData ? JSON.parse(cachedData) : {};
}

function saveUserCacheToLocalStorage(userCache: Record<string, string>) {
    localStorage.setItem('userCache', JSON.stringify(userCache));
}